import { Injectable } from "@angular/core";
import { BaseService } from "./base.service";
import { BehaviorSubject, Observable, map } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { AppConfig } from "src/app.config";
import { KeyValue } from "@angular/common";
import { SnackbarComponent } from "../snackbar/snackbar.component";

@Injectable({
  providedIn: 'root',
})

/**
 * Provides helper methods to create routes.
 */
export class Cabinet extends BaseService {
  myAppUrl: any;
  menuToggle: BehaviorSubject<boolean>;
  constructor(
    private http: HttpClient,
    private router: Router,
    private dialog: MatDialog,
    private _snackBar: MatSnackBar
  ) {
    super();
  }
  clearStorage() {
    if (!localStorage.getItem('token')) {
      this.router.navigate(['/']);
    }
    var hours = AppConfig.settings.other.clearStorageHour;
    var now = new Date().getTime();
    var setupTime = localStorage.getItem('setupTime');
    if (setupTime == null) {
      localStorage.setItem('setupTime', now.toString());
    } else {
      if (now - parseInt(setupTime) > hours * 60 * 60 * 1000) {
        localStorage.clear();
        window.location.reload();
        localStorage.setItem('setupTime', now.toString());
      }
    }

    if (!localStorage.getItem('token')) {
      this.router.navigate(['/login']);
    }
  }
  // lookups

  getWeatherForecast(): Observable<any> {
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    let url = this.myAppUrl + 'WeatherForecast';
    return this.http.get<any>(url);
  }

  getinvoices(
    filterModel: any = null
  ): Observable<any> {
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    let url = this.myAppUrl + 'invoice/get-invoices';
    return this.http
      .post<any>(url, filterModel)
      .pipe(map((res) => res));
  }

  getinvoicesExcel(
    filterModel: any = null
  ): Observable<any> {
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    let url = this.myAppUrl + 'invoice/get-invoices-exel';
    return this.http
      .post<any>(url, filterModel)
      .pipe(map((res) => res));
  }

  getinvoicesTemplateExcel(
    filterModel: any = null
  ): Observable<any> {
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    let url = this.myAppUrl + 'agreement/get-invoices-template-excel';
    return this.http
      .post<any>(url, filterModel)
      .pipe(map((res) => res));
  }

  getinvoiceById(
    id
  ): Observable<any> {
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    let url = this.myAppUrl + 'invoice/get-by-id?id=' + id;
    return this.http
      .get<any>(url)
      .pipe(map((res) => res));
  }

  getinvoiceByIdForAgreement(
    id
  ): Observable<any> {
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    let url = this.myAppUrl + 'invoice/get-by-id-for-agreement?id=' + id;
    return this.http
      .get<any>(url)
      .pipe(map((res) => res));
  }

  deleteInvoice(id: number): Observable<any> {
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    let url = this.myAppUrl + 'invoice/delete?id=' + id;
    return this.http.get<any>(url);
  }

  addInvoice(model: any): Observable<any> {
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    let url = this.myAppUrl + 'invoice/create';
    return this.http.post<any>(url, model);
  }

  addInvoiceByExcel(model: any): Observable<any> {
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    let url = this.myAppUrl + 'invoice/create-invoices-by-uploaded-excel';
    return this.http.post<any>(url, model);
  }

  editInvoice(model: any): Observable<any> {
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    let url = this.myAppUrl + 'invoice/update';
    return this.http.post<any>(url, model);

  }/////
  getBuildings(
    filterModel: any
  ): Observable<any> {
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    let url = this.myAppUrl + 'building/get-buildings';
    return this.http
      .post<any>(url, filterModel)
      .pipe(map((res) => res));
  }
  getBuildingsExel(filterModel): Observable<any> {
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    let url = this.myAppUrl + 'building/get-buildings-exel';
    return this.http.post<any>(url, filterModel).pipe(map((res) => res));
  }

  getVendorsAll(
  ): Observable<any> {
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    let url = this.myAppUrl + 'vendor/get-vendors';
    return this.http
      .get<any>(url)
      .pipe(map((res) => res));
  }

  getVendors(
    filterModel: any
  ): Observable<any> {
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    let url = this.myAppUrl + 'vendor/get-vendors';
    return this.http
      .post<any>(url, filterModel)
      .pipe(map((res) => res));
  }
  getVendorsExcel(filterModel): Observable<any> {
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    let url = this.myAppUrl + 'vendor/get-vendors-excel';
    return this.http.post<any>(url, filterModel).pipe(map((res) => res));
  }

  getServiceProviders(
    filterModel: any
  ): Observable<any> {
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    let url = this.myAppUrl + 'ServiceProvider/get-service-providers';
    return this.http
      .post<any>(url, filterModel)
      .pipe(map((res) => res));
  }

  getServiceProvidersAll(
  ): Observable<any> {
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    let url = this.myAppUrl + 'ServiceProvider/get-service-providers';
    return this.http
      .get<any>(url)
      .pipe(map((res) => res));
  }

  getServiceProviderExcel(filterModel): Observable<any> {
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    let url = this.myAppUrl + 'ServiceProvider/get-service-providers-excel';
    return this.http.post<any>(url, filterModel).pipe(map((res) => res));
  }

  getGetDebtHistories(filterModel: any): Observable<any> {
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    let url = this.myAppUrl + 'DebtHistory/get-debt-histories';
    return this.http
      .post<any>(url, filterModel)
      .pipe(map((res) => res));
  }

  getGetDebtHistoriesExcel(filterModel): Observable<any> {
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    let url = this.myAppUrl + 'DebtHistory/get-debt-histories-excel';
    return this.http.post<any>(url, filterModel).pipe(map((res) => res));
  }

  deleteServiceProvider(id: number): Observable<any> {
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    let url = this.myAppUrl + 'ServiceProvider/delete?id=' + id;
    return this.http.get<any>(url);
  }

  addServiceProvider(model: any): Observable<any> {
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    let url = this.myAppUrl + 'ServiceProvider/create';
    return this.http.post<any>(url, model);
  }
  editServiceProvider(model: any): Observable<any> {
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    let url = this.myAppUrl + 'ServiceProvider/update';
    return this.http.post<any>(url, model);
  }

  getBuilding(spId = null): Observable<any> {
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    let url = this.myAppUrl + 'building/get-all' + (spId ? '?spId=' + spId : '');
    return this.http
      .get<any>(url)
      .pipe(map((res) => res));
  }


  getCustomersAll(filterModel: any): Observable<any> {
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    let url = this.myAppUrl + "tenant/get-all";
    return this.http.post<any>(url, filterModel).pipe(
      map(
        res => res
      )
    )
  }

  changeCustomerPhone(model): Observable<any> {
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    let url = this.myAppUrl + "tenant/change-phone";
    return this.http.post<any>(url, model).pipe(
      map(
        res => res
      )
    )
  }

  getCustomersExel(filterModel): Observable<any> {
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    let url = this.myAppUrl + "tenant/get-all-exel";
    return this.http.post<any>(url, filterModel).pipe(
      map(
        res => res
      )
    )
  }

  getEmployees(filterModel: any): Observable<any> {
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    let url = this.myAppUrl + "employee/get-employees";
    return this.http.post<any>(url, filterModel).pipe(
      map(
        res => res
      )
    )
  }
  getEmployeesExcel(filterModel): Observable<any> {
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    let url = this.myAppUrl + "employee/get-employees-excel";
    return this.http.post<any>(url, filterModel).pipe(
      map(
        res => res
      )
    )
  }

  getRequests(filterModel: any): Observable<any> {
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    let url = this.myAppUrl + "request/get-requests";
    return this.http.post<any>(url, filterModel).pipe(
      map(
        res => res
      )
    )
  }
  getRequestsExcel(filterModel): Observable<any> {
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    let url = this.myAppUrl + "request/get-requests-excel";
    return this.http.post<any>(url, filterModel).pipe(
      map(
        res => res
      )
    )
  }

  getAllCustomers(filterModel: any): Observable<any[]> {
    filterModel.pageSize = 9999;
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    let url = this.myAppUrl + "tenant/get-all";
    return this.http.post<any>(url, filterModel).pipe(
      map(
        res => res.response.data
      )
    )
  }

  addBuilding(model: any): Observable<any> {
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    let url = this.myAppUrl + 'Building/create';
    return this.http.post<any>(url, model);
  }
  editBuilding(model: any): Observable<any> {
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    let url = this.myAppUrl + 'Building/update';
    return this.http.post<any>(url, model);
  }
  deleteBuilding(id: number): Observable<any> {
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    let url = this.myAppUrl + 'Building/delete?id=' + id;
    return this.http.get<any>(url);
  }

  addVendor(model: any): Observable<any> {
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    let url = this.myAppUrl + 'vendor/create';
    return this.http.post<any>(url, model);
  }
  editVendor(model: any): Observable<any> {
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    let url = this.myAppUrl + 'vendor/update';
    return this.http.post<any>(url, model);
  }
  deleteVendor(id: number): Observable<any> {
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    let url = this.myAppUrl + 'vendor/delete?id=' + id;
    return this.http.get<any>(url);
  }

  addCostumerBuilding(model: any): Observable<any> {
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    let url = this.myAppUrl + 'Building/create-tenant';
    return this.http.post<any>(url, model);
  }

  addBuildingEmployee(model: any): Observable<any> {
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    let url = this.myAppUrl + 'employee/add-building';
    return this.http.post<any>(url, model);
  }

  addCustomer(model: any): Observable<any> {
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    let url = this.myAppUrl + "tenant/create";
    return this.http.post<any>(url, model);
  }
  editCustomer(model: any): Observable<any> {
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    let url = this.myAppUrl + "tenant/update";
    return this.http.post<any>(url, model);
  }

  editEmployee(model: any): Observable<any> {
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    let url = this.myAppUrl + "employee/update";
    return this.http.post<any>(url, model);
  }

  editRequest(model: any): Observable<any> {
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    let url = this.myAppUrl + "request/update";
    return this.http.post<any>(url, model);
  }

  addEmployee(model: any): Observable<any> {
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    let url = this.myAppUrl + "employee/create";
    return this.http.post<any>(url, model);
  }

  addRequest(model: any): Observable<any> {
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    let url = this.myAppUrl + "request/create";
    return this.http.post<any>(url, model);
  }

  deleteCustomer(id: number): Observable<any> {
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    let url = this.myAppUrl + "tenant/delete?id=" + id;
    return this.http.get<any>(url);
  }

  deleteEmployee(id): Observable<any> {
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    let url = this.myAppUrl + "employee/delete?id=" + id;
    return this.http.get<any>(url);
  }
  //

  getHistories(
    filterModel: any
  ): Observable<any> {
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    let url = this.myAppUrl + 'history/get-histories';
    return this.http
      .post<any>(url, filterModel)
      .pipe(map((res) => res));
  }

  getHistoriesExel(buildingId: number): Observable<any> {
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    let url =
      this.myAppUrl + 'history/get-histories-exel?buildingId=' + buildingId;
    return this.http.get<any>(url).pipe(map((res) => res));
  }

  addHistory(model: any): Observable<any> {
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    let url = this.myAppUrl + 'History/create';
    return this.http.post<any>(url, model);
  }
  editHistory(model: any): Observable<any> {
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    let url = this.myAppUrl + 'History/update';
    return this.http.post<any>(url, model);
  }
  deleteHistory(id: number): Observable<any> {
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    let url = this.myAppUrl + 'History/delete?id=' + id;
    return this.http.get<any>(url);
  }

  // Services

  getServices(
    filterModel: any
  ): Observable<any> {
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    let url = this.myAppUrl + 'Service/get-all';
    return this.http
      .post<any>(url, filterModel)
      .pipe(map((res) => res));
  }

  // getServicesExel(buildingId: number): Observable<any> {
  //   this.myAppUrl = AppConfig.settings.other.resourceApiURI;
  //   let url = this.myAppUrl + 'service/get-all-exel?buildingId=' + buildingId;
  //   return this.http
  //     .get<any>(url)
  //     .pipe(map((res) => res));
  // }

  getServicesExel(buildingId: number): Observable<any> {
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    let url = this.myAppUrl + 'service/get-all-exel?buildingId=' + buildingId;
    return this.http.post<any>(url, buildingId).pipe(map((res) => res));
  }


  addService(model: any): Observable<any> {
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    let url = this.myAppUrl + 'Service/create';
    return this.http.post<any>(url, model);
  }
  editService(model: any): Observable<any> {
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    let url = this.myAppUrl + 'Service/update';
    return this.http.post<any>(url, model);
  }
  deleteService(id: number): Observable<any> {
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    let url = this.myAppUrl + 'Service/delete?id=' + id;
    return this.http.get<any>(url);
  }

  getPayments(filterModel: any): Observable<any> {
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    let url = this.myAppUrl + 'payment/get-payments';
    return this.http
      .post<any>(url, filterModel)
      .pipe(map((res) => res));
  }

  getPaymentsByServiceProvider(filterModel: any): Observable<any> {
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    let url = this.myAppUrl + 'payment/get-payments-by-service-provider';
    return this.http
      .post<any>(url, filterModel)
      .pipe(map((res) => res));
  }

  getPaymentsByChannel(filterModel: any): Observable<any> {
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    let url = this.myAppUrl + 'payment/get-payments-by-channel';
    return this.http
      .post<any>(url, filterModel)
      .pipe(map((res) => res));
  }

  getPaymentsByChannelExcel(filterModel: any): Observable<any> {
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    let url = this.myAppUrl + 'payment/get-payments-by-channel-excel';
    return this.http
      .post<any>(url, filterModel)
      .pipe(map((res) => res));
  }

  getPaymentsByChannelOperationsExcel(filterModel: any): Observable<any> {
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    let url = this.myAppUrl + 'payment/get-payments-by-channel-operations-excel';
    return this.http
      .post<any>(url, filterModel)
      .pipe(map((res) => res));
  }

  getPaymentsByCompaniesOperationsExcel(filterModel: any): Observable<any> {
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    let url = this.myAppUrl + 'payment/get-payments-by-sp-operations-excel';
    return this.http
      .post<any>(url, filterModel)
      .pipe(map((res) => res));
  }

  getPaymentsByServiceProviderExcel(filterModel: any): Observable<any> {
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    let url = this.myAppUrl + 'payment/get-payments-by-service-provider-excel';
    return this.http
      .post<any>(url, filterModel)
      .pipe(map((res) => res));
  }

  getPaymentsExel(filterModel: any): Observable<any> {
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    let url = this.myAppUrl + 'payment/get-payments-exel';
    return this.http
      .post<any>(url, filterModel)
      .pipe(map((res) => res));
  }
  // getPaymentTypes(): Observable<any> {
  //   this.myAppUrl = AppConfig.settings.other.resourceApiURI;
  //   let url = this.myAppUrl + 'PaymentType/get-all';
  //   return this.http.get<any>(url).pipe(map((res) => res));
  // }

  addPayment(model: any): Observable<any> {
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    let url = this.myAppUrl + 'request/add-payment';
    return this.http.post<any>(url, model);
  }

  updateProfileInfo(model: any): Observable<any> {
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    let url = this.myAppUrl + 'Profile/update';
    return this.http.post<any>(url, model).pipe(map((res) => res));
  }
  getProfileInfo(): Observable<any> {
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    let url = this.myAppUrl + 'Profile/get-info';
    return this.http
      .get<any>(url)
      .pipe(map((res) => res));
  }
  changePassword(model: any): Observable<any> {
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    let url = this.myAppUrl + 'Auth/change-password';
    return this.http.post<any>(url, model).pipe(map((res) => res));
  }

  getAgreements(
    filterModel: any = null
  ): Observable<any> {
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    let url = this.myAppUrl + 'Agreement/get-all';
    return this.http
      .post<any>(url, filterModel)
      .pipe(map((res) => res));
  }

  getDebtByAgreementName(
    filterModel: any = null
  ): Observable<any> {
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    let url = this.myAppUrl + 'Agreement/get-debt-by-agreement-name';
    return this.http
      .post<any>(url, filterModel)
      .pipe(map((res) => res));
  }

  getYoUrl(customerCode): Observable<any> {
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    let url = this.myAppUrl + 'Agreement/get-yo-url?customerCode=' + customerCode;
    return this.http
      .get<any>(url)
      .pipe(map((res) => res));
  }

  sendSms(
    filterModel: any = null
  ): Observable<any> {
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    let url = this.myAppUrl + 'Agreement/send-sms';
    return this.http
      .post<any>(url, filterModel)
      .pipe(map((res) => res));
  }

  getAgreementsExel(filterModel): Observable<any> {
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    let url = this.myAppUrl + 'Agreement/get-all-exel';
    return this.http
      .post<any>(url, filterModel)
      .pipe(map((res) => res));
  }
  deleteAgreement(id: number): Observable<any> {
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    let url = this.myAppUrl + 'Agreement/delete?id=' + id;
    return this.http.get<any>(url);
  }

  addAgreement(model: any): Observable<any> {
    console.log(model.endDate);
    model.endDate ? 'test' : null
    console.log(model);
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    let url = this.myAppUrl + 'Agreement/create';
    return this.http.post<any>(url, model);
  }
  editAgreement(model: any): Observable<any> {
    model.endDate == '' ? null : model.endDate
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    let url = this.myAppUrl + 'Agreement/update';
    return this.http.post<any>(url, model);
  }
  getAgreement(id: number): Observable<any> {
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    let url = this.myAppUrl + 'Agreement/get-by-id?id=' + id;
    return this.http
      .get<any>(url)
      .pipe(map((res) => res.response));
  }

  getAgreementServices(agreementId: number): Observable<Array<any>> {
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    let url =
      this.myAppUrl + 'Agreement/get-services?agreementId=' + agreementId;
    return this.http
      .get<any>(url)
      .pipe(map((res) => res.response.data));
  }

  getAllServices(model: any): Observable<Array<any>> {
    model.pageSize = 9999;
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    let url = this.myAppUrl + 'Service/get-all';
    return this.http
      .post<any>(url, model)
      .pipe(map((res) => res.response.data));
  }
  getCustomerDetails(id: number): Observable<any> {
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    let url = this.myAppUrl + "tenant/get-detail-by-id?id=" + id;
    return this.http.get<any>(url);
  }

  activateService(id: number): Observable<any> {
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    let url = this.myAppUrl + 'agreement/activate-agreement-service?id=' + id;
    return this.http.get<any>(url);
  }

  deActivateService(id: number): Observable<any> {
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    let url = this.myAppUrl + 'agreement/deactivate-agreement-service?id=' + id;
    return this.http.get<any>(url);
  }
  downloadFile(name: string): Observable<any> {
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    let url = this.myAppUrl + 'file/get-file?name=' + name;
    return this.http.get<any>(url);
  }

  getServiceType(): Observable<any> {
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    let url = this.myAppUrl + 'Lookup/get-service-type';
    return this.http.get<any>(url);
  }

  // getServiceProvidersAll(): Observable<any> {
  //   this.myAppUrl = AppConfig.settings.other.resourceApiURI;
  //   let url = this.myAppUrl + 'Lookup/get-service-providers';
  //   return this.http.get<any>(url);
  // }

  getServiceProviderTypesAll(): Observable<any> {
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    let url = this.myAppUrl + 'Lookup/get-service-provider-types';
    return this.http.get<any>(url);
  }

  getRoles(): Observable<any> {
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    let url = this.myAppUrl + 'employee/get-employee-roles';
    return this.http.get<any>(url);
  }

  getEmployeesAll(): Observable<any> {
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    let url = this.myAppUrl + 'employee/get-employees';
    return this.http.get<any>(url);
  }

  getBuildingServices(buildingId): Observable<any> {
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    let url = this.myAppUrl + 'Lookup/get-building-services?buildingId=' + buildingId;
    return this.http.get<any>(url);
  }

  getInvoicingType(): Observable<any> {
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    let url = this.myAppUrl + 'Lookup/get-invoicing-type';
    return this.http.get<any>(url);
  }
  getInvoicingPeriod(): Observable<any> {
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    let url = this.myAppUrl + 'Lookup/get-invoicing-period';
    return this.http.get<any>(url);
  }


  getCustomers(): Observable<any> {
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    let url = this.myAppUrl + 'Lookup/get-customers';
    return this.http.get<any>(url);
  }

  getPaymentTypes(): Observable<any> {
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    let url = this.myAppUrl + 'Lookup/get-payment-types';
    return this.http.get<any>(url);
  }

  getCustBuild(id): Observable<any> {
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    let url = this.myAppUrl + 'Lookup/get-cus-build?id=' + id;
    return this.http.get<any>(url);
  }

  getBuildService(id): Observable<any> {
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    let url = this.myAppUrl + 'Lookup/get-building-services?buildingId=' + id;
    return this.http.get<any>(url);
  }



  getAbonentNumbers(
  ): Observable<Array<KeyValue<number, string>>> {
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    let url = this.myAppUrl + 'Invoice/get-abonent-numbers';
    return this.http
      .get<any>(url)
      .pipe(map((res) => res.data));
  }
  getSpCostumers(
  ): Observable<Array<KeyValue<number, string>>> {
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    let url = this.myAppUrl + 'Building/get-sp-costumers';
    return this.http
      .get<any>(url)
      .pipe(map((res) => res.data));
  }

  getEmloyees(
  ): Observable<Array<KeyValue<number, string>>> {
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    let url = this.myAppUrl + 'lookup/get-employees';
    return this.http
      .get<any>(url)
      .pipe(map((res) => res));
  }

  getBuildingNames(
  ): Observable<Array<KeyValue<number, string>>> {
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    let url = this.myAppUrl + 'Building/get-building-names';
    return this.http
      .get<any>(url)
      .pipe(map((res) => res.data));
  }
  GetCostumerBuildingList(
  ): Observable<Array<KeyValue<number, number>>> {
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    let url = this.myAppUrl + 'Building/get-costumer-building';
    return this.http
      .get<any>(url)
      .pipe(map((res) => res.data));
  }

  GetBuildingEmployeeList(
  ): Observable<Array<KeyValue<number, number>>> {
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    let url = this.myAppUrl + 'employee/get-building-employee';
    return this.http
      .get<any>(url)
      .pipe(map((res) => res.data));
  }

  getAgreementList(id: number, customerId): Observable<Array<KeyValue<number, string>>> {
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    let url = this.myAppUrl + 'Agreement/get-agreements-list?buildid=' + id + '&customerId=' + customerId;
    return this.http
      .get<any>(url)
      .pipe(map((res) => res.data));
  }
  getInvoiceList(spCostumerId: number): Observable<Array<KeyValue<number, string>>> {
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    let url = this.myAppUrl + 'Invoice/get-invoice-list?spCostumerId=' + spCostumerId;
    return this.http
      .get<any>(url)
      .pipe(map((res) => res.data));
  }
  ////////////////////////////////////////////////////
  sortByName(data) {
    let lng = 'az';
    const collator = new Intl.Collator(lng);
    data = data.sort(function (a, b) {
      return collator.compare(a.name, b.name);
    });
    return data;
  }

  sortByNameAz(data) {
    let lng = 'az';
    const collator = new Intl.Collator(lng);
    data = data.sort(function (a, b) {
      return collator.compare(a.nameAz, b.nameAz);
    });
    return data;
  }

  sortByOrgName(data) {
    let lng = 'az';
    const collator = new Intl.Collator(lng);
    data = data.sort(function (a, b) {
      return collator.compare(a.orgName, b.orgName);
    });
    return data;
  }

  sortByCode(data) {
    let lng = 'az';
    const collator = new Intl.Collator(lng);
    data = data.sort(function (a, b) {
      return collator.compare(a.code, b.code);
    });
    return data;
  }
  //   public messageDialog(text: string, isRefresh: boolean) {
  //     this.dialog.open(MessageDialog, {
  //       width: '500px',
  //       position: {
  //         top: '10px',
  //       },
  //       data: { Text: `${text}`, isRefresh: isRefresh },
  //       autoFocus: false,
  //     });
  //   }

  //   public messageObjectDialog(obj: any) {
  //     this.dialog.open(MessageDialog, {
  //       width: '650px',
  //       position: {
  //         top: '10px',
  //       },
  //       data: { obj_message: `${obj}` },
  //       autoFocus: true,
  //     });
  //   }

  showBasicComponent(message: string, snackClass: string) {
    this._snackBar.openFromComponent(SnackbarComponent, {
      data: { message: `${message}` },
      duration: 3000,
      panelClass: [snackClass]
    });
  }


  //   public loadingDialog(): MatDialogRef<any> {
  //     return this.dialog.open(LoadingDialogComponent, {
  //       position: {
  //         top: '10px',
  //       },
  //       autoFocus: false,
  //     });
  //   }
}
